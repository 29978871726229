var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":"mdi-text-box","title":_vm.title},scopedSlots:_vm._u([{key:"tools",fn:function(){return [(!_vm.myTickets)?_c('s-icon-report-download',{attrs:{"report":"114","show-message":""}}):_vm._e(),(!_vm.myTickets)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":_vm.showGraphs ? 'error' : 'success'},on:{"click":function($event){_vm.showGraphs = !_vm.showGraphs}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.showGraphs ? 'mdi-eye-off-outline' : 'mdi-eye-check-outline'))])],1)]}}],null,false,854178760)},[_c('span',[_vm._v(_vm._s(_vm.showGraphs ? 'Ocultar indicadores' : 'Exibir indicadores'))])]):_vm._e(),(_vm.selectedGraph)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.clickGraph(null)}}},[_vm._v("Limpar Filtros"),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-filter-remove-outline")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.showOpenTicket = true}}},[_vm._v("Abrir um chamado "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-text-box-plus")])],1)]},proxy:true}])},[(_vm.showGraphs && !_vm.myTickets)?_c('v-row',{staticClass:"mb-2"},_vm._l((_vm.graphs),function(graph,i){return _c('CardTicketTotal',{key:i,attrs:{"item":graph,"selectedItem":_vm.selectedGraph},on:{"click":_vm.clickGraph}})}),1):_vm._e(),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemEdit":_vm.model,"loading":_vm.loading,"showActions":false},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('EmcButtonIcon',{key:item.id,attrs:{"icon":"mdi-text-box","color":"grey","text":"Detalhes do chamado " + item.ticket_number},on:{"click":function(value) { _vm.showForm = value; _vm.model = item;}}}),(_vm.user.is_internal)?_c('EmcButtonIcon',{attrs:{"icon":"mdi-delete-outline","color":"error","text":"Excluir chamado " + item.ticket_number},on:{"click":function(value) { _vm.itemDelete = item; _vm.showDelete = true;}}}):_vm._e()]}},{key:"item.ticket_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ticket_number)+" "),(!_vm.myTickets && _vm.user.is_internal && item.assigned)?_c('small',[_c('br'),_vm._v("Atríbuído para:"),_c('br'),_vm._v(_vm._s(item.assigned.name))]):_vm._e()]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.name)+" "),_c('small',[_c('br'),_vm._v(_vm._s(item.institution.name))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.created_at))+" "),_c('br'),_c('small',[_vm._v(_vm._s(item.created_at_human))])]}},{key:"item.category.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"grey darken-1","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.category.icon))]),_vm._v(" "+_vm._s(item.category.name)+" ")],1)]}},{key:"item.status.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"grey darken-1","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":item.status.color}},[_vm._v(_vm._s(item.status.icon))]),_vm._v(" "+_vm._s(item.status.name)+" ")],1)]}},{key:"item.type.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"grey darken-1","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.type.icon))]),_vm._v(" "+_vm._s(item.type.name)+" ")],1)]}},{key:"item.type_real.name",fn:function(ref){
var item = ref.item;
return [(item.ticket_type_id_real)?_c('v-chip',{attrs:{"label":"","text-color":"grey darken-1","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.type_real.icon))]),_vm._v(" "+_vm._s(item.type_real.name)+" ")],1):_c('v-chip',{attrs:{"label":"","text-color":"grey darken-1","small":"","outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.type.icon))]),_vm._v(" "+_vm._s(item.type.name)+" ")],1)]}},(!_vm.myTickets && _vm.user.is_internal)?{key:"footer.prepend",fn:function(){return [_c('v-switch',{staticClass:"ml-3",attrs:{"inset":"","label":"Exibir chamados atribuídos a mim"},model:{value:(_vm.onlyMyTickets),callback:function ($$v) {_vm.onlyMyTickets=$$v},expression:"onlyMyTickets"}})]},proxy:true}:null],null,true)}),_c('OpenTicket',{attrs:{"show":_vm.showOpenTicket},on:{"update:show":function (value) { return _vm.showOpenTicket = value; },"onCompleted":_vm.onSave}}),_c('FormTicketDetails',{attrs:{"show":_vm.showForm,"model":_vm.model},on:{"update:show":function (value) { return _vm.showForm = value; },"onCompleted":_vm.onSave}}),_c('emc-alert-modal',{attrs:{"text":"Deseja excluir o chamado " + (_vm.itemDelete ? _vm.itemDelete.ticket_number : '') + "?","disabled":_vm.deleting,"show":_vm.showDelete},on:{"update:show":function($event){_vm.showDelete=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }